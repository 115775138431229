// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-entry-blog-js": () => import("./../../../src/templates/entry_blog.js" /* webpackChunkName: "component---src-templates-entry-blog-js" */),
  "component---src-templates-entry-gallery-js": () => import("./../../../src/templates/entry_gallery.js" /* webpackChunkName: "component---src-templates-entry-gallery-js" */),
  "component---src-templates-entry-images-js": () => import("./../../../src/templates/entry_images.js" /* webpackChunkName: "component---src-templates-entry-images-js" */),
  "component---src-templates-entry-js": () => import("./../../../src/templates/entry.js" /* webpackChunkName: "component---src-templates-entry-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-list-blog-js": () => import("./../../../src/templates/list_blog.js" /* webpackChunkName: "component---src-templates-list-blog-js" */),
  "component---src-templates-list-gallery-js": () => import("./../../../src/templates/list_gallery.js" /* webpackChunkName: "component---src-templates-list-gallery-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */)
}

